<template>
  <div class="md:p-4">
    <div class="max-w-xl">
      <sqr-page-header title="Quelle type de demande d'offre ?" class="p-4" />
      <sqr-progress v-show="loading || adding" />
      <div class="bg-white shadow overflow-hidden sm:rounded-md" v-if="!adding">
        <ul>
          <li
            v-for="model in models"
            :key="model.id"
            class="border-t border-gray-200 first:border-t-0"
          >
            <a
              class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
              @click="add(model)"
            >
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="min-w-0 flex-1 flex items-center">
                  <div
                    class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                  >
                    <div>
                      <div
                        class="text-sm leading-5 font-medium text-blue-600 truncate"
                      >
                        {{ model.name }}
                      </div>
                    </div>
                    <div class="hidden md:block"></div>
                  </div>
                </div>
                <div>
                  <fa :icon="['far', 'chevron-right']" />
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fromPairs, is } from 'ramda';

import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';

import nanoRef from '@/utils/nanoRef';
import stamp from '@/utils/stamp';

import broker from '../broker';

const capitalize = s => {
  if (typeof s !== 'string' || s.length < 1) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function buildAddress(addr) {
  if (!addr) return '';
  const fields = [
    [addr.streetAddress, addr.streetNumber],
    [addr.postOfficeBoxNumber],
    [addr.postalCode, addr.addressLocality, addr.addressRegion],
    [addr.addressCountry]
  ];
  const lines = fields.map(line => line.filter(v => Boolean(v)).join(' '));
  const address = lines.filter(v => Boolean(v)).join('\n');
  return address;
}

function buildAnswers(rel) {
  const relationName = [rel.company, rel.givenName, rel.familyName]
    .filter(v => Boolean(v))
    .join(' ');

  const relationAddress = buildAddress(rel.address);

  const relationDrivingLicenseDate = rel?.driver?.drivingLicenseDate ?? '';

  const pairs = Object.entries(rel)
    .filter(([key, value]) => is(String, value) || is(Number, value))
    .map(([key, value]) => ['relation' + capitalize(key), value]);

  const basics = fromPairs(pairs);

  return {
    ...basics,
    relationName,
    relationAddress,
    relationDrivingLicenseDate
  };
}

export default {
  name: 'InquiryAdd',
  mixins: [broker],
  components: { SqrPageHeader, SqrButton, SqrErrorBanner },
  computed: {
    ...mapState('inquiryModels', { models: 'docs', loadError: 'error' }),
    ...mapState('inquiryModels', ['loading'])
  },
  data() {
    return {
      adding: false,
      addError: null
    };
  },
  mounted() {
    const bid = this.bid;
    this.sub({
      path: `brokers/${bid}/offer-models`,
      sortBy: [['name', 'asc']]
    });
  },
  methods: {
    ...mapActions('inquiryModels', ['sub', 'unsub']),
    async add(model) {
      const bid = this.bid;
      try {
        this.adding = true;
        const reference = nanoRef(5);
        this.addError = null;
        this.adding = true;

        let answers = {};
        const rid = this.$route.query.rid;
        if (rid) {
          const relRes = await this.$db()
            .doc(`brokers/${bid}/relations/${rid}`)
            .get();
          const relation = relRes.data();
          answers = buildAnswers(relation);
        }

        const created = stamp();

        const res = await this.$db()
          .collection(`brokers/${bid}/inquiries`)
          .add({
            name: `${model.name} - ${reference}`,
            reference,
            model,
            answers,
            recipientIds: [],
            status: 'draft',
            created,
            updated: created
          });
        this.$router.replace({
          name: 'inquiry-fill',
          params: { bid, iid: res.id }
        });
      } catch (error) {
        this.addError = error;
        return Promise.reject(error);
      } finally {
        this.adding = false;
      }
    }
  }
};
</script>
